import getMenuItems from '../services/menu';

const initialState = getMenuItems();

export default function navigation(state = initialState, action, payload = initialState) {
  switch (action.type) {
    case "clear":
      return payload
    default:
      return state
  }
}
